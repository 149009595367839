import GameCanvas from './components/Game';
import './App.css';
import { Page } from './page/Page';

function App() {
  return (
    <div>
      <Page />
    </div>
  );
}

export default App;
