import React from "react";

export const Header = (props) => {
  return (
    <div
      style={{
        textAlign: "center",
        color: "white",
        fontSize: 96,
        fontFamily: "Staatliches",
        paddingTop: "100px",
      }}
    >
      {"Pentaquad"}
    </div>
  );
};
